import React from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'

const PreviewCard = ({ to, fluid, alt, title, description, className }) => (
  <li className={`${className} w-full rounded overflow-hidden shadow-lg mb-8 md:mb-0 shadow-lg hover:shadow-xl transition-shadow duration-150 ease-in-out`}>
    <Link to={to}>
      <Img className="w-full" fluid={fluid} alt={alt} />
      <div className="px-6 py-4">
        <h5 className="text-xl leading-8 font-bold text-gray-900 tracking-tight">{title}</h5>
        <p className="text-base leading-6 text-gray-500">
          {description}
        </p>
      </div>
    </Link>
  </li>
)

export default PreviewCard