import React from 'react'
import { YellowButton } from '../../Global/Button'
import { GiantsCausewayTour, BelfastCityTour, GameOfThronesTour, CarrickARedeTour } from '../../Tours/Preview'
import SectionTitle from '../../Global/SectionTitle'

const Tours = () => (
  <div className="py-24">
    <div className="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">

      <SectionTitle
        supertitle="Private Tours"
        title="A Better Way to Explore Ireland"
        text="Let Chauffeur Me take you on a journey to discover the best that Ireland has to offer with a range of private tours available."
      />

      <div className="mt-10">
        <ul className="md:grid md:grid-cols-2 lg:grid-cols-3 md:col-gap-8 md:row-gap-10 mb-12">
          <GiantsCausewayTour />
          <GameOfThronesTour />
          <BelfastCityTour />
          <CarrickARedeTour className="lg:hidden" />
        </ul>
        <div className="mt-5 max-w-md mx-auto sm:flex sm:justify-center md:mt-8">
          <YellowButton to="/tours" className="flex items-center justify-center rounded-sm shadow">More Tours</YellowButton>
        </div>
      </div>
      
    </div>
  </div>
)

export default Tours