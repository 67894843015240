import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import PreviewCard from './PreviewCard'

const BelfastPoliticalTour = () => {
  const { politicaltour } = useStaticQuery(graphql`
    query {
      politicaltour: file(relativePath: { eq: "belfast-murals.jpg" }) {
        childImageSharp {
          fluid(quality: 80, maxWidth: 720, maxHeight: 405) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  return (
    <PreviewCard
      to="/tours/belfast-political-tour-from-belfast"
      fluid={politicaltour.childImageSharp.fluid}
      alt="Chauffeur Me Belfast Political Tour – Peace Wall"
      title="Belfast Political Tour"
      description="Few cities have been transformed as dramatically as Belfast – the bad old days of The Troubles are a thing of the past, and there is now plenty to be discovered."
    />
  )
}

export default BelfastPoliticalTour