import React from 'react'
import PropTypes from 'prop-types'

const SectionTitle = ({ supertitle, title, text }) => (
  <div className="lg:text-center">
    {supertitle && <p className="text-base leading-6 text-yellow-300 font-semibold tracking-wider uppercase">{supertitle}</p>}
    <h3 className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
      {title}
    </h3>
    <p className="mt-4 max-w-3xl text-xl leading-7 text-gray-500 lg:mx-auto">
      {text}
    </p>
  </div>
)

export default SectionTitle

SectionTitle.propTypes = {
  supertitle: PropTypes.string,
  title: PropTypes.string.isRequired,
  text: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.node
  ]),
}

SectionTitle.defaultProps = {
  supertitle: null
}