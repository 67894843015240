import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import { YellowButton } from '../../Global/Button'

const Golf = () => {

  const { backgroundImage } = useStaticQuery(graphql`
    query {
      backgroundImage: file(relativePath: { eq: "golf-ireland-royal-county-down.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 900) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <div className="max-w-screen-xl mx-auto md:grid md:grid-cols-5">
      <BackgroundImage
        Tag="div"
        className={`relative h-64 w-full col-span-2 md:h-auto`}
        fluid={backgroundImage.childImageSharp.fluid}
      ></BackgroundImage>
      <div className="px-4 md:px-8 py-16 lg:py-24 lg:pr-32 xl:pr-40 lg:pl-16 col-span-3">
        <h3 className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10 pb-4">Discover Ireland's World Famous Golf Courses with Chauffeur Driven Golf Trips</h3>
        <hr className="border-b-2 border-yellow-300 w-48 mb-8" />
        <p className="mt-4 text-base leading-6 text-gray-500">Legendary links golf, famous and historic courses and world champions: golf on the Ireland of Ireland is world renowned – and for good reason!</p>
        <p className="mt-4 text-base leading-6 text-gray-500">We can provide our luxury chauffeur-driven transport for you to enjoy 18 holes at some of Ireland's World famous courses. We'll look after everything from your return travel to tee-times, hotels to dinner. We hope your play can match our standard of service.</p>
        <YellowButton to="/services/chauffeur-driven-golf-trips" className="inline-flex mt-6 shadow-md">About Us</YellowButton>
      </div>
    </div>
)}

export default Golf