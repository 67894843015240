import React from 'react'
import Img from 'gatsby-image'
import { graphql, useStaticQuery } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'

import TALogo from '../../../assets/images/ta-logo-primary.svg'

const Hero = () => {
  const { backgroundImage } = useStaticQuery(graphql`
    query {
      backgroundImage: file(relativePath: { eq: "banner-image.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1200) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <BackgroundImage
      Tag="section"
      id="hero"
      className={`relative bg-black`}
      fluid={backgroundImage.childImageSharp.fluid}
    >
      <div className="mx-auto max-w-screen-xl px-4 sm:px-6 lg:px-8 relative z-20">
        <div className="text-center lg:text-left py-16 lg:py-32">
          <h2 className="uppercase tracking-wider leading-8 lg:leading-10 text-white font-normal text-xl md:text-2xl lg:text-3xl mb-6">
            Executive Chauffeur Services <br className="hidden sm:block" /><span className="pl-2 sm:pl-0">Based in Belfast, Northern Ireland.</span>
          </h2>
          <img src={TALogo} alt="Tripadvisor Logo" className="inline-block" />
        </div>
      </div>
      <Img fluid={backgroundImage.childImageSharp.fluid} alt="Chauffeur Me Executive Mercedes Vehicle" className="-mt-16 sm:-mt-32 relative z-10 lg:hidden" />
      <div className="max-w-screen-xl mx-auto mt-16 px-4 sm:px-6 lg:px-8 pb-16 md:pb-20">
        <ul className="sm:grid sm:grid-cols-2 xl:grid-cols-4 sm:col-gap-24 sm:row-gap-10">
          <li>
            <div className="flex sm:flex-col md:flex-row xl:flex-col">
              <div className="flex items-center justify-center flex-shrink-0 h-12 w-12 rounded-md bg-yellow-400 text-white mb-4">
                <svg className="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 7L20 7M20 7L16 3M20 7L16 11M16 17L4 17M4 17L8 21M4 17L8 13" />
                </svg>
              </div>
              <div className="pl-6 sm:pl-0 md:pl-6 xl:pl-0">
                <h5 className="text-lg leading-6 font-medium text-white pb-2 border-b-2 border-yellow-400">Airport Transfers</h5>
                <p className="mt-2 text-base leading-6 text-gray-300 leading-8">
                  Pickup &amp; drop-off services to and from Belfast International, Belfast City and Dublin International Airports.
                </p>
              </div>
            </div>
          </li>
          <li className="mt-10 sm:mt-0">
            <div className="flex sm:flex-col md:flex-row xl:flex-col">
              <div className="flex items-center justify-center flex-shrink-0 h-12 w-12 rounded-md bg-yellow-400 text-white mb-4">
                <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24">
                  <path strokeWidth="2" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" d="M17.6569 16.6569C16.7202 17.5935 14.7616 19.5521 13.4138 20.8999C12.6327 21.681 11.3677 21.6814 10.5866 20.9003C9.26234 19.576 7.34159 17.6553 6.34315 16.6569C3.21895 13.5327 3.21895 8.46734 6.34315 5.34315C9.46734 2.21895 14.5327 2.21895 17.6569 5.34315C20.781 8.46734 20.781 13.5327 17.6569 16.6569Z" />
                  <path strokeWidth="2" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" d="M15 11C15 12.6569 13.6569 14 12 14C10.3431 14 9 12.6569 9 11C9 9.34315 10.3431 8 12 8C13.6569 8 15 9.34315 15 11Z" />
                </svg>
              </div>
              <div className="pl-6 sm:pl-0 md:pl-6 xl:pl-0">
                <h5 className="text-lg leading-6 font-medium text-white pb-2 border-b-2 border-yellow-400">Cruise Ship Excursions</h5>
                <p className="mt-2 text-base leading-6 text-gray-300">
                  See the best Belfast and Ireland have to offer after docking at Belfast Port with our luxury, chauffeur driven, cruise ship excursions.
                </p>
              </div>
            </div>
          </li>
          <li className="mt-10 sm:mt-0">
            <div className="flex sm:flex-col md:flex-row xl:flex-col">
              <div className="flex items-center justify-center flex-shrink-0 h-12 w-12 rounded-md bg-yellow-400 text-white mb-4">
                <svg className="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                  <path strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" d="M12 15V17M6 21H18C19.1046 21 20 20.1046 20 19V13C20 11.8954 19.1046 11 18 11H6C4.89543 11 4 11.8954 4 13V19C4 20.1046 4.89543 21 6 21ZM16 11V7C16 4.79086 14.2091 3 12 3C9.79086 3 8 4.79086 8 7V11H16Z" />
                </svg>
              </div>
              <div className="pl-6 sm:pl-0 md:pl-6 xl:pl-0">
                <h5 className="text-lg leading-6 font-medium text-white pb-2 border-b-2 border-yellow-400">Private Tours</h5>
                <p className="mt-2 text-base leading-6 text-gray-300">
                  Explore the best sights in Ireland with personalised tours with our knowlegable and experienced chauffeur drivers.
                </p>
              </div>
            </div>
          </li>
          <li className="mt-10 sm:mt-0">
            <div className="flex sm:flex-col md:flex-row xl:flex-col">
              <div className="flex items-center justify-center flex-shrink-0 h-12 w-12 rounded-md bg-yellow-400 text-white mb-4">
                <svg className="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                  <path strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" d="M4.31802 6.31802C2.56066 8.07538 2.56066 10.9246 4.31802 12.682L12.0001 20.364L19.682 12.682C21.4393 10.9246 21.4393 8.07538 19.682 6.31802C17.9246 4.56066 15.0754 4.56066 13.318 6.31802L12.0001 7.63609L10.682 6.31802C8.92462 4.56066 6.07538 4.56066 4.31802 6.31802Z" />
                </svg>
              </div>
              <div className="pl-6 sm:pl-0 md:pl-6 xl:pl-0">
                <h5 className="text-lg leading-6 font-medium text-white pb-2 border-b-2 border-yellow-400">Weddings</h5>
                <p className="mt-2 text-base leading-6 text-gray-300">
                  Professional wedding car service with experienced chauffeur drivers for transport on your special day.
                </p>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </BackgroundImage>
  )
}
export default Hero