import React from "react"

import Layout from "../components/Layout"
import Hero from "../components/Home/Hero";
import SEO from "../components/Global/SEO";
import Featured from "../components/Home/Featured";
import Tours from "../components/Home/Tours";
import Golf from "../components/Home/Golf";
import { useStaticQuery, graphql } from "gatsby";

const IndexPage = ({ location }) => {

  const { seoImg } = useStaticQuery(graphql`
    query {
      seoImg: file(relativePath: { eq: "chauffeur-me-van-at-titanic-belfast.jpg" }) {
        childImageSharp {
          fixed(quality: 90, width: 1200, height: 630) {
            src
          }
        }
      }
    }
  `)
  
  return (
    <Layout>
      <SEO
        title="Luxury Executive Chauffeur Services | Belfast, Dublin, Ireland"
        description="Chauffeur Me provides executive and luxury chauffeur services throughout Ireland for everything from corporate events to weddings and private tours to golf trips."
        image={seoImg.childImageSharp.fixed.src}
        pathname={location.pathname}
      />
      <Hero />
      <Featured />
      <Golf />
      <Tours />
    </Layout>
  )
}

export default IndexPage
