import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import PreviewCard from './PreviewCard'

const GameOfThronesTour = () => {
  const { got } = useStaticQuery(graphql`
    query {
      got: file(relativePath: { eq: "dark-hedges.jpg" }) {
        childImageSharp {
          fluid(quality: 80, maxWidth: 720, maxHeight: 405) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  return (
    <PreviewCard
      to="/tours/game-of-thrones-tour-from-belfast"
      fluid={got.childImageSharp.fluid}
      alt="Game of Thrones Tour Cauffeur Me Tour Location – Dark Hedges"
      title="Game of Thrones Tour"
      description="Discover the world of Westeros in all its natural beauty as we visit some the Northern Ireland locations used in the hit HBO series."
    />
  )
}

export default GameOfThronesTour