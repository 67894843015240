import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import PreviewCard from './PreviewCard'

const BelfastCityTour = () => {
  const { citytour } = useStaticQuery(graphql`
    query {
      citytour: file(relativePath: { eq: "titanic-belfast.jpg" }) {
        childImageSharp {
          fluid(quality: 80, maxWidth: 720, maxHeight: 405) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  return (
    <PreviewCard
      to="/tours/belfast-city-tour-from-belfast"
      fluid={citytour.childImageSharp.fluid}
      alt="Chauffeur Me Belfast City Tour Location – Titanic Building"
      title="Belfast City Tour"
      description="Explore the City of Belfast with it's historic landmarks and famous attractions which bring tourists from around the world to this beautiful city."
    />
  )
}

export default BelfastCityTour