import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import { YellowButton } from '../../Global/Button'

const Featured = () => {

  const { backgroundImage } = useStaticQuery(graphql`
    query {
      backgroundImage: file(relativePath: { eq: "mercedes-interior-3.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <div className="max-w-screen-xl mx-auto md:grid md:grid-cols-5">
      <div className="px-4 md:px-8 py-16 lg:py-24 lg:pr-32 xl:pr-40 lg:pl-16 md:col-span-3">
        <h3 className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10 pb-4">Executive Chauffeur Services for Business &amp; Leisure</h3>
        <hr className="border-b-2 border-yellow-300 w-48 mb-8" />
        <p className="mt-4 text-base leading-6 text-gray-500">Looking for a reliable and professional Belfast Chauffeur hire service? Look no further than chauffeur Me. Our experienced chauffeurs understand the importance of providing a discreet and professional, first class chauffeur service for both business and leisure travel.</p>
        <p className="mt-4 text-base leading-6 text-gray-500">Our fleet of luxury vehicles includes saloon cars that are perfect for up to 3 passengers with luggage, and are equipped with air conditioning and WiFi for your comfort. We also offer chauffeured MPV vehicles which can accommodate larger groups for your Belfast transportation needs. Book your Belfast chauffeur hire now with chauffeur Me for a smooth and stress-free journey.</p>
        <YellowButton to="/about" className="mt-8 inline-block">About Us</YellowButton>
      </div>
      <BackgroundImage
        Tag="div"
        className={`relative h-64 w-full md:col-span-2 md:h-auto`}
        fluid={backgroundImage.childImageSharp.fluid}
      ></BackgroundImage>
    </div>
)}

export default Featured