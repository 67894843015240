import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import PreviewCard from './PreviewCard'

const CarrickARedeTour = ({ className }) => {
  const { carrickarede } = useStaticQuery(graphql`
    query {
      carrickarede: file(relativePath: { eq: "rope-bridge.jpg" }) {
        childImageSharp {
          fluid(quality: 80, maxWidth: 720, maxHeight: 405) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  return (
    <PreviewCard
      to="/tours/carrick-a-rede-rope-bridge-tour-from-belfast"
      fluid={carrickarede.childImageSharp.fluid}
      alt="Chauffeur Me Carrick-a-Rede Rope Bridge Tour – Carrick-a-Rede Rope Bridge"
      title="Carrick-a-Rede Rope Bridge Tour"
      description="Thrill seekers from all around world come to experience the exhilarating Carrick-a-Rede Rope Bridge swaying 30 metres above the Atlantic Ocean."
      className={className}
    />
  )
}

export default CarrickARedeTour