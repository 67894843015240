import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import PreviewCard from './PreviewCard'

const BespokeTour = ({ className }) => {
  const { bespoke } = useStaticQuery(graphql`
    query {
      bespoke: file(relativePath: { eq: "bushmills.jpg" }) {
        childImageSharp {
          fluid(quality: 80, maxWidth: 720, maxHeight: 405) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  return (
    <PreviewCard
      to="/tours/bespoke-tour-from-belfast"
      fluid={bespoke.childImageSharp.fluid}
      alt="Chauffeur Me Bespoke Tours – Bushmills Distillery"
      title="Bespoke Tour"
      description="See what you want to see with a bespoke tour of Ireland. Let us know the places you want to visit and we'll organise your trip for you."
    />
  )
}

export default BespokeTour