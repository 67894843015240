import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import PreviewCard from './PreviewCard'

const GiantsCausewayTour = () => {
  const { giantscauseway } = useStaticQuery(graphql`
    query {
      giantscauseway: file(relativePath: { eq: "giants-causeway.jpg" }) {
        childImageSharp {
          fluid(quality: 80, maxWidth: 720, maxHeight: 405) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  return (
    <PreviewCard
      to="/tours/giants-causeway-tour-from-belfast"
      alt="Giant's Causeway Chauffeur Me Tour Location"
      fluid={giantscauseway.childImageSharp.fluid}
      title="Giant's Causeway Tour"
      description="The spectacular drive along Antrim's Causeway Coast is one of the world's finest, many attractions lie on the route including the famous Giant's Causeway."
    />
  )
}

export default GiantsCausewayTour